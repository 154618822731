@tailwind base;
@tailwind components;
@tailwind utilities;

.react-flow__node-selectorNode {
  font-size: 12px;
  background: #eee;
  border: 1px solid #555;
  border-radius: 5px;
  text-align: center;
}

.react-flow__panel a {
  display: none;
}

.color-picker .hue-horizontal {
  border-radius: '100px !important';
}

.flow-zone .react-flow__panel.react-flow__minimap {
  width: 100px;
  height: 100px;
  overflow: hidden;
  right: 50px;
}

.flow-zone .react-flow__panel.react-flow__minimap svg {
  width: 100px;
  height: 100px;
}